/*
 *   File : manage-course.js
 *   Author : https://evoqins.com
 *   Description : Manage course page
 *   Integrations : react-toastify,js-cookie,draftjs-to-html
 *   Version : 1.0.0
*/

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cookies from 'js-cookie';

// Custom component
import { PrimaryButton, TextButton } from '../../../Component/Buttons';
import { CustomFileUpload, CustomSelectBox, CustomTextInput, TagSelector, TextArea, CustomEditor } from '../../../Component/FormElements';
import { Icon } from '../../../Component/Icon';
import { APIService, AWSService } from '../../../Service';
import { Loader } from '../../../Component/Others';

//styles
import styles from "../../../Style/Component/input.module.scss";

const CATEGORY = [
    {
        value: 1,
        label: 'Product',
    },
    {
        value: 2,
        label: 'Skills & Services'
    },
];

const RESOURCE_TYPE = [
    {
        value: 1,
        label: 'Video',
        type: 'VIDEO'
    },
    {
        value: 2,
        label: 'Document',
        type: 'DOC'
    },
    {
        value: 3,
        label: 'PDF',
        type: 'PDF'
    },
    {
        value: 4,
        label: 'PPT',
        type: 'PPT'
    },
];

const MODULE_ERROR = {
    title: "",
    description: "",
    course_url: "",
    type: "",
    duration_in_text: "",
    thumbnail_url: ""
};

const TAGS = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const ManageCourse = () => {

    const { state } = useLocation();
    const moduleRef = useRef([]);
    const navigate = useNavigate();

    const [adminType, setAdminType] = useState(Cookies.get("admin_type") === "1" ? "super_admin" : "country_admin");

    const [resourceName, setResourceName] = useState("");
    const [resourceNameError, setResourceNameError] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [courseId, setCourseId] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [thumbnailError, setThumbnailError] = useState("");
    const [thumbnailLoader, setThumbnailLoader] = useState(true);

    const [totalTime, setTotalTime] = useState("");
    const [totalTimeError, setTotalTimeError] = useState("");

    const [selectedCategoryType, setSelectedCategoryType] = useState(null);
    const [selectedCategoryTypeError, setSelectedCategoryTypeError] = useState("");
    const [rewardPoint, setRewardPoint] = useState("");
    const [rewardPointError, setRewardPointError] = useState("");
    const [modules, setModules] = useState([]);

    const [productList, setProductList] = useState([]);
    const [productValue, setProductValue] = useState([]);

    const [editData, setEditData] = useState({});
    const [loading, setLoading] = useState(true);

    const [createLoader, setCreateLoader] = useState(true); //loader for button
    const [productSelect, setProductSelect] = useState(false);


    const [toggleCourseType, setToggleCourseType] = useState(false);
    const [editor, setEditor] = useState(() => EditorState.createEmpty());

    const [editorData, setEditorData] = useState(null);
    const [editorError, setEditorError] = useState("");

    const [moduleUploadLoading, setModuleUploadLoading] = useState([]);

    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [countrySelect, setCountrySelect] = useState(false);

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categorySelect, setCategorySelect] = useState(false);

    const [errorUpdate, setErrorUpdate] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        _getProducts();
        _getCategoryList();
        setThumbnailLoader(false);
    }, []);

    useEffect(() => {
        if (adminType === "super_admin") {
            _getCountries();
        }
    }, [adminType]);

    useEffect(() => {
        if (state !== null) {
            if (state.type === "edit") {
                setCourseId(state.course_id);
            } else {
                setModules([{
                    title: "",
                    description: "",
                    thumbnail_url: null,
                    course_url: null,
                    type: null,
                    duration_in_text: "",
                    errors: MODULE_ERROR,
                    id: 1
                }]); // setting default value
                setLoading(false);
            }

        } else {
            navigate("/manage-resource");
        }
        setCreateLoader(false);

    }, [state]);

    useEffect(() => {
        if (courseId != null) {
            setLoading(true);
            _getCourseDetail();
        }
    }, [courseId]);

    useEffect(() => {
        setResourceNameError("");
    }, [resourceName]);

    useEffect(() => {
        setDescriptionError("");
    }, [description]);

    useEffect(() => {
        setThumbnailError("");
    }, [thumbnail]);

    useEffect(() => {
        setSelectedCategoryTypeError("");
    }, [selectedCategoryType]);

    useEffect(() => {
        setTotalTimeError("");
    }, [totalTime]);

    useEffect(() => {
        setRewardPointError("");
    }, [rewardPoint]);

    useEffect(() => {
        setEditorError("");
    }, [editorData]);

    // handle toggle course type
    const _handleToggleCourseType = () => {
        setToggleCourseType(!toggleCourseType);
    };

    // handle edit editor
    const _handleEditorChange = (data) => {
        setEditor(data);
        _getHtmlFormat();
    };

    // convert html format 
    const _getHtmlFormat = () => {
        const content = editor.getCurrentContent();
        const raw_content = convertToRaw(content);
        const html_content = draftToHtml(raw_content);

        setEditorData({ content: html_content });
    };

    // handle upload thumbnail
    const _handleUploadThumbnail = (url) => {
        setThumbnailLoader(true);
        _getPresignedUrl(url, true);
    };

    // handle change thumbnail
    const _handleChangeThumbnail = () => {
        setThumbnail(null);
    }

    // handle Change
    const _handleChange = useMemo(() => (index, field, updated_value) => {
        const current_module = [...modules];

        if (field === "type") {
            const selected_resource_type = RESOURCE_TYPE.findIndex((type) => type.label === updated_value.label);
            let selected_module_type = updated_value;
            current_module[index] = {
                ...modules[index],
                [field]: RESOURCE_TYPE[selected_resource_type].type,
                module_type: selected_module_type,
                course_url: null,
                errors: {
                    ...modules[index].errors,
                    [field]: "",
                }
            };

        } else {
            current_module[index] = {
                ...modules[index],
                [field]: updated_value,
                errors: {
                    ...modules[index].errors,
                    [field]: "" // Clear error for the edited field
                }
            };
        }
        setModules(current_module);
    }, [modules]);

    // handle add more section
    const _handleAddSection = () => {
        setModules(prev => [
            ...prev, {
                title: "",
                description: "",
                thumbnail_url: null,
                course_url: null,
                type: null,
                duration_in_text: "",
                errors: MODULE_ERROR,
                id: prev.length + 1
            }
        ]);
    };

    // handle delete each section
    const _handleDeleteSection = (index) => {
        const new_sections = [...modules];
        new_sections.splice(index, 1);
        setModules(new_sections);
    };

    // handle upload 
    const _uploadImage = (index, field, url) => {

        setModuleUploadLoading(prev => {
            const newState = [...prev];
            newState[index] = { ...newState[index], [field]: true };
            return newState;
        });

        _getPresignedUrl(url, false, index, field);
    };

    // handle change thumbnail
    const _handleChangeImage = (index, field) => {
        _handleChange(index, field, null);
    }

    // handle Country change
    const _handleCountryChange = (value) => {
        setSelectedCountries(value);
    };

    // handle tag change
    const _handleTagChange = (value) => {
        setProductValue(value);
    };

    // handle Category change
    const _handleCategoryChange = (value) => {
        setSelectedCategories(value);
    };

    // validate the form
    const _validate = () => {
        let is_valid = true;

        // Check title
        if (resourceName.trim().length === 0) {
            setResourceNameError("Resource name is required");
            is_valid = false;
        } else {
            setResourceNameError("");
        }

        if (description.trim().length === 0) {
            setDescriptionError("Description is required");
            is_valid = false;
        } else {
            setDescriptionError("");
        }

        if (totalTime.trim().length === 0) {
            setTotalTimeError("Total time is required");
            is_valid = false;
        } else {
            setTotalTimeError("");
        }

        if (thumbnail === null) {
            setThumbnailError("Thumbnail is required");
            is_valid = false;
        } else {
            setThumbnailError("");
        }

        if (selectedCategoryType === null) {
            setSelectedCategoryTypeError("Select category");
            is_valid = false;
        } else {
            setSelectedCategoryTypeError("");

        }

        if (rewardPoint.length === 0) {
            setRewardPointError("Reward point is required");
            is_valid = false;
        } else {
            setRewardPointError("");
        }

        if (toggleCourseType === false) {
            // for section error
            const new_section = modules.map((data) => {
                const error_object = {};

                if (data.title.trim().length === 0) {
                    error_object.title = "Resource title is required";
                    is_valid = false;
                } else {
                    error_object.title = "";
                }

                if (data.description.trim().length === 0) {
                    error_object.description = "Description is required";
                    is_valid = false;
                } else {
                    error_object.description = "";
                }

                if (data.type === null) {
                    error_object.type = "Select resource type";
                    is_valid = false;
                } else {
                    error_object.type = "";
                }

                if (data.type === "VIDEO") {
                    if (data.thumbnail_url === null) {
                        error_object.thumbnail_url = "Thumbnail is required";
                        is_valid = false;
                    } else {
                        error_object.thumbnail_url = "";
                    }
                }

                if (data.type !== null) {
                    if (data.course_url === null) {
                        error_object.course_url = "Resource is required";
                        is_valid = false;
                    } else {
                        error_object.course_url = "";
                    }
                }

                if (data.duration_in_text.trim().length === 0) {
                    error_object.duration_in_text = "Duration is required";
                    is_valid = false;
                } else {
                    error_object.duration_in_text = "";
                }

                return { ...data, errors: error_object };
            });

            // focus error 
            if (state.type === "edit") {
                for (let i = 0; i < new_section.length; i++) {
                    const section = new_section[i];
                    if (section.errors && Object.values(section.errors).some(error => error.trim().length !== 0)) {
                        if (moduleRef.current[i]) {
                            moduleRef.current[i].scrollIntoView({ behavior: 'smooth', block: 'start', });
                            break;
                        }
                    }
                }
            }

            setModules(new_section);
        } else {
            if (editorData === null || _validateEmptyTag(editorData.content, TAGS)) {
                setEditorError("Content is required");
                is_valid = false;
            } else {
                setEditorError("");
            }
        }
        setErrorUpdate(prevValue => !prevValue);
        return is_valid;
    };

    // handle validate empty tag 
    const _validateEmptyTag = (html_value, tag_value) => {
        let element = document.createElement('div');
        element.innerHTML = html_value;

        for (let tag of tag_value) {
            let elements = element.getElementsByTagName(tag);
            for (let element of elements) {
                let innerHTML = element.innerHTML.trim();
                if (innerHTML === '' || innerHTML === '&nbsp;') {
                    return true;
                }
            }
        }
        return false;
    };

    // handle back
    const _handleBack = () => {
        if (state.type === "edit") {
            navigate("/manage-resource/course-detail", {
                state: {
                    course_id: courseId
                }
            });
        } else {
            navigate("/manage-resource", {
                state: 1
            });
        }
    };

    // handle submit
    const _handleSubmit = () => {
        if (_validate()) {

            setCreateLoader(true);
            if (state.type === "edit") {
                _updateCourse();
            } else {
                _createCourse();
            }
        } else {
            console.log("error");
        }
    };

    // Common function to get module type
    const _getModuleType = (type) => {
        switch (type) {
            case 'VIDEO':
                return RESOURCE_TYPE[0];
            case 'DOC':
                return RESOURCE_TYPE[1];
            case 'PDF':
                return RESOURCE_TYPE[2];
            default:
                return RESOURCE_TYPE[3];
        }
    };

    // get presigned url
    async function _getPresignedUrl(file, type, index = null, field = null) {
        let is_post = true;
        let url = 'general/file-upload';
        let data = {
            "file": file.name,
            "is_public": true
        };
        try {
            let response = await APIService(is_post, url, data);

            if (response.status_code == 200) {
                let aws_fields = response.data.upload_info.fields;
                let aws_url = response.data.upload_info.url;

                try {
                    let aws_response = await AWSService(aws_fields, file, aws_url);
                    if (aws_response.status == 'ok') {
                        if (type) {
                            setThumbnail(response.data.view_info);
                        } else {
                            // const new_data = [...modules];
                            // new_data[index][field] = response.data.view_info;
                            // setModules(new_data);

                            setModules(prev => {
                                const updated_module = [...prev];
                                updated_module[index][field] = response.data.view_info;

                                const updated_error = { ...updated_module[index].errors };
                                updated_error[field] = '';

                                updated_module[index].errors = updated_error;
                                return updated_module;
                            });

                            setModuleUploadLoading(prev => {
                                const newState = [...prev];
                                newState[index] = { ...newState[index], [field]: false };
                                return newState;
                            });
                        }
                    } else {
                        toast.dismiss();
                        toast.error(aws_response.message, {
                            type: "error",
                            className: "e-toast",
                        });
                    }
                } catch (err) {
                    console.log("error")
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        } catch (err) {
        }
        setThumbnailLoader(false);
    };

    // API - Create course
    const _createCourse = () => {

        const updated_value = productValue.map(item => item.value);
        const updated_country_ids = selectedCountries.map(item => item.value);
        const updated_categories = selectedCategories.map(item => item.value);

        let is_post = true;
        let url = adminType === "super_admin" ? 'course/create' : 'CA/course/create';
        let data = {
            "title": resourceName,
            "description": description,
            "thumbnail_url": thumbnail,
            "total_time": totalTime,
            "reward": parseInt(rewardPoint),
            "type": selectedCategoryType.value,
            "course_type": toggleCourseType === false ? 1 : 2, // change it while doing course with HTML editor
            "modules": toggleCourseType === false ? modules : editorData,
            "linked_products": updated_value,
            "product_categories": updated_categories,
            "country_ids": updated_country_ids
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Course created successfully", {
                    type: "success",
                    className: "e-toast",
                });
                // navigate("/manage-resource");

                navigate('/manage-resource/manage-quiz', {
                    state: {
                        course_id: response.data.course_id,
                        type: ""
                    }
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - update course
    const _updateCourse = () => {

        const updated_value = productValue.map(item => item.value);
        const updated_country_ids = selectedCountries.map(item => item.value);
        const updated_categories = selectedCategories.map(item => item.value);

        let is_post = true;
        let url = adminType === "super_admin" ? 'course/update' : 'CA/course/update';
        let data = {
            "course_id": editData.course_id,
            "title": resourceName,
            "description": description,
            "thumbnail_url": thumbnail,
            "total_time": totalTime,
            "reward": parseInt(rewardPoint),
            "type": selectedCategoryType.value,
            "course_type": toggleCourseType === false ? 1 : 2,
            "modules": toggleCourseType === false ? modules : editorData,
            "linked_products": updated_value,
            "product_categories": updated_categories,
            "country_ids": updated_country_ids
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                toast.dismiss();
                toast.success("Course updated successfully", {
                    type: "success",
                    className: "e-toast",
                });

                navigate("/manage-resource/course-detail", {
                    state: {
                        course_id: courseId
                    }
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - course detail
    const _getCourseDetail = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'course/detail' : 'CA/course/detail';
        let data = {
            "course_id": courseId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                let course_info = response.data;
                let modules = response.data.modules;

                for (let index = 0; index < modules.length; index++) {
                    let module_type = modules[index].type;

                    let selected_module_type = _getModuleType(module_type);

                    course_info.modules[index].module_type = selected_module_type;
                    course_info.modules[index].errors = MODULE_ERROR;
                }

                setResourceName(course_info.title);
                setDescription(course_info.description);
                setThumbnail(course_info.thumbnail_url);
                setSelectedCategoryType({ value: course_info.type_id, label: course_info.type_text });
                setTotalTime(course_info.duration_text);
                setRewardPoint(course_info.points);
                setModules(course_info.modules);
                setSelectedCountries(course_info.country_ids);
                setSelectedCategories(course_info.product_categories);

                //changing 
                const updated_linked_products = course_info.linked_products.map(product => {
                    return {
                        value: product.product_id,
                        label: product.title,
                        description: product.description,
                        thumbnail_url: product.thumbnail_url
                    };
                });
                setProductValue(updated_linked_products);
                setEditData(course_info);

                if (course_info.course_type_id === 2) {
                    setToggleCourseType(true);
                    const html_content = htmlToDraft(course_info.modules.content); //convert html to draft
                    const content = ContentState.createFromBlockArray(html_content.contentBlocks);
                    const updated_content = EditorState.createWithContent(content);

                    setEditor(updated_content);
                }
                setLoading(false);
            } else {
                navigate("/manage-resource");
            }
        });
    };

    // API - get products
    const _getProducts = () => {
        let is_post = true;
        let url = 'general/products';
        let data = {
            "query": "",
            "page_num": 1
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setProductList(response.data);
                setProductSelect(true);
            } else {
                setProductSelect(false);
            }
        });
    };

    // API - Get countries
    const _getCountries = () => {
        let is_post = false;
        let url = 'general/list-countries';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCountries(response.data);
                setCountrySelect(true);
            } else {
                setCountrySelect(false);
            }
        });
    };

    // API - Get Category list
    const _getCategoryList = () => {
        let is_post = false;
        let url = 'general/filter-data';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCategoryList(response.data);
                setCategorySelect(true);
            } else {
                setCategorySelect(false);
            }
        });
    };

    return (
        <section className='pb-5 '>
            <div className={`${adminType === "super_admin" ? 'e-bg-azureish-white' : 'e-bg-lavender-blush-light'} pt-3 padding-98px-bottom padding-52px-lr`}>
                <div className='e-display-flex'>
                    <Icon icon="arrow-left"
                        className='cursor-pointer'
                        size="24px"
                        onPress={() => _handleBack()} />
                    <h1 className='ps-1 mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                        {state?.type === "edit" ? `Edit course` : `Add new course`}
                    </h1>
                </div>
            </div>

            <div className='margin-neg-66px-top padding-52px-lr'>
                {loading ?
                    // loader 
                    <Loader />
                    :
                    <div className='e-bg-white e-admin-box-shadow p-4 e-border-radius-16 '>
                        <div className='d-flex gap-0-8 justify-content-space-between'>
                            <h3 className='mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                                {state.type === "edit" ? `Course details` : `Add course details`}
                            </h3>

                            {/* Repeat notification */}
                            <div className='d-flex gap-4 align-items-center'>
                                {state.type === "" && <div className='e-display-flex justify-content-space-between '>
                                    <p className='pe-2 mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-16 e-font-weight-400 line-height-24px'>
                                        Add resource without video
                                    </p>
                                    <label className={`${styles.e_resource} `} >
                                        <input type="checkbox"
                                            checked={toggleCourseType}
                                            onChange={_handleToggleCourseType} />
                                        <span className={`${styles.e_slider}`}></span>
                                    </label>
                                </div>
                                }

                                <PrimaryButton name="Save & update"
                                    className={`${createLoader ? 'padding-6px-tb' : ' py-2'} e-border-radius-16 e-font-16 w-146px`}
                                    status={createLoader}
                                    onPress={_handleSubmit} />
                            </div>
                        </div>

                        {/* input section */}
                        <div className='row'>
                            <div className='col-xl-9 col-lg-9 col-md-10 col-12'>
                                {/* resource name  */}
                                <CustomTextInput label="Course name"
                                    placeholder="Successful negotiation essential strategies & skills"
                                    type="text"
                                    require
                                    value={resourceName}
                                    error={resourceNameError}
                                    className="w-100 padding-10px-tb"
                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                    borderStyle="e-border-1px-platinum-light"
                                    handleChange={(e) => setResourceName(e)} />

                                {/* description */}
                                <TextArea label="Course description"
                                    placeholder="Enter your text..."
                                    type="text"
                                    rows="3"
                                    value={description}
                                    error={descriptionError}
                                    require
                                    className="w-100 px-3 padding-10px-tb"
                                    handleChange={(e) => setDescription(e)} />

                                {/* thumbnail */}
                                <CustomFileUpload label="Upload thumbnail"
                                    placeholder="Drop your image here"
                                    file={thumbnail}
                                    fileType={["JPG", "PNG", "JPEG"]}
                                    resolution="156px * 140px"
                                    require
                                    requiredWidth={156}
                                    requiredHeight={140}
                                    errorUpdate={errorUpdate}
                                    error={thumbnailError}
                                    loader={thumbnailLoader}
                                    handleChange={_handleUploadThumbnail} />

                                {/* change thumbnail button */}
                                {thumbnail !== null &&
                                    <div className='mb-2 margin-neg-16px-top'>
                                        <TextButton name="Change thumbnail"
                                            onPress={_handleChangeThumbnail} />
                                    </div>
                                }

                                <div className='row '>
                                    {/* Course type */}
                                    <div className='col-6'>
                                        <CustomSelectBox label="Type"
                                            value={selectedCategoryType}
                                            options={CATEGORY}
                                            error={selectedCategoryTypeError}
                                            className="e-section-select e-bg-white"
                                            isSearchable={false}
                                            selectChange={(id) => { setSelectedCategoryType(id); }}
                                        />
                                    </div>

                                    {/* tag selector */}
                                    <div className='col-6'>
                                        {categorySelect === true &&
                                            <TagSelector label="Category"
                                                value={selectedCategories}
                                                options={categoryList}
                                                className="e-bg-white e-border-radius-12"
                                                handleTagChange={_handleCategoryChange} />
                                        }
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-6'>
                                        {/* resource name  */}
                                        <CustomTextInput label="Reward point"
                                            placeholder="100"
                                            type="number"
                                            require
                                            value={rewardPoint}
                                            error={rewardPointError}
                                            className="w-100 padding-10px-tb"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            borderStyle="e-border-1px-platinum-light"
                                            handleChange={(e) => setRewardPoint(e)} />
                                    </div>

                                    {/* Total time */}
                                    <div className='col-6'>
                                        <CustomTextInput label="Total time"
                                            placeholder="2 min"
                                            type="text"
                                            require
                                            value={totalTime}
                                            error={totalTimeError}
                                            className="w-100 padding-10px-tb"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            borderStyle="e-border-1px-platinum-light"
                                            handleChange={(e) => setTotalTime(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {toggleCourseType === false ?
                            <>
                                {/* Add sections */}
                                {
                                    modules.map((module, key) => {
                                        return (
                                            <div key={key} ref={error => moduleRef.current[key] = error} className='row pb-2 pt-2 '>
                                                <div className='col-xl-9 col-lg-9 col-md-10 col-12'>

                                                    <div className={`${key === 0 ? 'justify-content-between' : 'justify-content-end'} d-flex align-items-center`}>
                                                        {key === 0 &&
                                                            <h3 className='mb-3 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                                                                {state?.type === "edit" ? "Edit modules" : "Add modules"}
                                                            </h3>
                                                        }

                                                        {modules.length > 1 &&
                                                            <p className='mb-2 text-end e-text-lust e-font-16 e-alt-font-noto-sans e-font-weight-600 line-height-22px cursor-pointer text-decoration-hover-underline'
                                                                onClick={() => _handleDeleteSection(key)} >
                                                                Delete
                                                            </p>
                                                        }
                                                    </div>

                                                    {/* Module name */}
                                                    <div className='e-bg-ghost-white p-3 e-border-radius-16 transition'>
                                                        <CustomTextInput label="Module name"
                                                            placeholder="Successful negotiation essential strategies & skills"
                                                            type="text"
                                                            require
                                                            value={module.title}
                                                            error={module.errors.title}
                                                            className="w-100 padding-10px-tb e-border-radius-8"
                                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                                            borderStyle="e-border-1px-platinum-light"
                                                            handleChange={(e) => _handleChange(key, 'title', e)} />

                                                        {/* description */}
                                                        <TextArea label="Module description"
                                                            placeholder="Enter your text..."
                                                            type="text"
                                                            rows="3"
                                                            value={module.description}
                                                            error={module.errors.description}
                                                            require
                                                            className="w-100 px-3 padding-10px-tb"
                                                            handleChange={(e) => _handleChange(key, 'description', e)} />

                                                        {/* Resource type */}
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <CustomSelectBox label="Resource type"
                                                                    value={module.module_type}
                                                                    options={RESOURCE_TYPE}
                                                                    error={module.errors.type}
                                                                    className="e-section-select e-bg-white e-border-radius-8"
                                                                    isSearchable={false}
                                                                    selectChange={(id) => {
                                                                        _handleChange(key, 'type', id)
                                                                    }} />
                                                            </div>

                                                            {/* Duration */}
                                                            <div className='col-6'>
                                                                <CustomTextInput label="Duration"
                                                                    placeholder="30 sec"
                                                                    type="text"
                                                                    require
                                                                    value={module.duration_in_text}
                                                                    error={module.errors.duration_in_text}
                                                                    className="w-100 padding-10px-tb e-border-radius-8"
                                                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                                                    borderStyle="e-border-1px-platinum-light "
                                                                    handleChange={(e) => _handleChange(key, 'duration_in_text', e)} />
                                                            </div>
                                                        </div>

                                                        {/* upload thumbnail */}
                                                        {module.module_type?.label === "Video" &&
                                                            <CustomFileUpload label="Upload thumbnail"
                                                                placeholder="Drop your image here"
                                                                file={module.thumbnail_url}
                                                                fileType={["JPG", "PNG", "JPEG"]}
                                                                resolution="328px * 210px"
                                                                requiredWidth={328}
                                                                requiredHeight={210}
                                                                errorUpdate={errorUpdate}
                                                                require
                                                                loader={moduleUploadLoading[key]?.thumbnail_url}
                                                                error={module.errors.thumbnail_url}
                                                                handleChange={(e) => _uploadImage(key, 'thumbnail_url', e)} />
                                                        }

                                                        {/* change thumbnail button */}
                                                        {module.thumbnail_url !== null &&
                                                            <div className='margin-neg-20px-top'>
                                                                <TextButton name="Change thumbnail"
                                                                    onPress={() => _handleChangeImage(key, 'thumbnail_url')} />
                                                            </div>
                                                        }

                                                        <>
                                                            {module.module_type?.label === "Video" ?
                                                                <>
                                                                    {/* upload video */}
                                                                    <CustomFileUpload label="Upload video"
                                                                        placeholder="Drop your video here"
                                                                        file={module.course_url}
                                                                        fileType={["MP4", "MOV",]}
                                                                        require
                                                                        type={2}
                                                                        support="MP4"
                                                                        loader={moduleUploadLoading[key]?.course_url}
                                                                        error={module.errors.course_url}
                                                                        handleChange={(e) => _uploadImage(key, 'course_url', e)} />

                                                                    {module.course_url !== null &&
                                                                        <div className='margin-neg-20px-top'>
                                                                            <TextButton name="Change video"
                                                                                onPress={() => _handleChangeImage(key, 'course_url')} />
                                                                        </div>
                                                                    }
                                                                </>
                                                                :
                                                                module.module_type?.label === "Document" ?
                                                                    <>
                                                                        {/* upload document */}
                                                                        <CustomFileUpload label="Upload document"
                                                                            placeholder="Drop your document here"
                                                                            file={module.course_url}
                                                                            fileType={["DOC", "DOCX"]}
                                                                            require
                                                                            type={3}
                                                                            support="DOC,DOCX"
                                                                            loader={moduleUploadLoading[key]?.course_url}
                                                                            error={module.errors.course_url}
                                                                            handleChange={(e) => _uploadImage(key, 'course_url', e)} />

                                                                        {module.course_url !== null &&
                                                                            <div className='margin-neg-8px-top'>
                                                                                <TextButton name="Change document"
                                                                                    onPress={() => _handleChangeImage(key, 'course_url')} />
                                                                            </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    module.module_type?.label === "PDF" ?
                                                                        <>
                                                                            {/* upload document */}
                                                                            <CustomFileUpload label="Upload PDF"
                                                                                placeholder="Drop your PDF here"
                                                                                file={module.course_url}
                                                                                fileType={["PDF"]}
                                                                                require
                                                                                type={3}
                                                                                support="PDF"
                                                                                loader={moduleUploadLoading[key]?.course_url}
                                                                                error={module.errors.course_url}
                                                                                handleChange={(e) => _uploadImage(key, 'course_url', e)} />

                                                                            {module.course_url !== null &&
                                                                                <div className='margin-neg-8px-top'>
                                                                                    <TextButton name="Change document"
                                                                                        onPress={() => _handleChangeImage(key, 'course_url')} />
                                                                                </div>
                                                                            }
                                                                        </>
                                                                        :
                                                                        module.module_type?.label === "PPT" ?
                                                                            <>
                                                                                {/* upload document */}
                                                                                <CustomFileUpload label="Upload PPT"
                                                                                    placeholder="Drop your PPT here"
                                                                                    file={module.course_url}
                                                                                    fileType={["PPT"]}
                                                                                    require
                                                                                    type={3}
                                                                                    support="PPT"
                                                                                    loader={moduleUploadLoading[key]?.course_url}
                                                                                    error={module.errors.course_url}
                                                                                    handleChange={(e) => _uploadImage(key, 'course_url', e)} />

                                                                                {module.course_url !== null &&
                                                                                    <div className='margin-neg-8px-top'>
                                                                                        <TextButton name="Change document"
                                                                                            onPress={() => _handleChangeImage(key, 'course_url')} />
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                            :
                                                                            null
                                                            }
                                                        </>
                                                    </div>
                                                </div>

                                                {/* add more module button */}
                                                <div className='col-xl-3 col-lg-3 col-md-10 col-12 my-auto text-xl-center text-lg-center text-end pt-xl-0 pt-lg-0 pt-2'>
                                                    {modules.length === key + 1 && modules.length < 4 &&
                                                        < p className='mb-0 e-text-indigo e-font-16 e-alt-font-noto-sans e-font-weight-600 line-height-22px cursor-pointer'
                                                            onClick={_handleAddSection} >
                                                            + <span className='cursor-pointer text-decoration-hover-underline '>Add new module</span>
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </>
                            :

                            // wysiwyg editor
                            <div className='row'>
                                <div className='col-xl-9 col-lg-9 col-md-10 col-12'>
                                    <h3 className='mb-3 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                                        Add resource
                                    </h3>

                                    {/* custom wysiwyg editor */}
                                    <CustomEditor editorState={editor}
                                        editorStyle={{ minHeight: "200px" }}
                                        options={['inline', 'blockType', 'list', 'link', 'textAlign']}
                                        className='h-268px'
                                        error={editorError}
                                        onEditorStateChange={_handleEditorChange} />

                                </div>
                            </div>
                        }

                        {/* Country select */}
                        {countrySelect === true &&
                            <div className='row pt-2'>
                                <div className='col-xl-9 col-lg-9 col-md-10 col-12'>
                                    <h3 className='mb-3 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                                        {state.type === "edit" ? "Manage Countries" : "Countries"}
                                    </h3>
                                    <div className='e-bg-ghost-white p-3 e-border-radius-16'>
                                        {/* Country select */}
                                        <TagSelector label="Country"
                                            value={selectedCountries}
                                            options={countries}
                                            className="e-bg-white e-border-radius-12"
                                            handleTagChange={_handleCountryChange} />
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Jotun products */}
                        <div className='row pt-3'>
                            <div className='col-xl-9 col-lg-9 col-md-10 col-12'>
                                <h3 className='mb-3 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                                    {state.type === "edit" ? "Manage products" : "Jotun products"}
                                </h3>
                                <div className='e-bg-ghost-white p-3 e-border-radius-16'>
                                    {/* Product name selector */}
                                    {productSelect &&
                                        <TagSelector label="Product name"
                                            value={productValue}
                                            options={productList.data}
                                            className="e-bg-white e-border-radius-12"
                                            handleTagChange={_handleTagChange} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </section >
    )
}

export default ManageCourse;